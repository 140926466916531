import { envConfig } from "../config";
import { showAlert } from "../utils/calc-util";
export class IGRECalcRepository {

    constructor() {
        this.calcBasePath = envConfig.calculatorBasePath;
    }
    getStates = async () => {
        try {
            const url = this.calcBasePath + "states";
            const res = await fetch(url, {
                method: "GET"
            });

            if (res.ok) {
                const data = await res.json();
                return data;


            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getStates", err);
                return null;
            }

        } catch (error) {
            console.log("error while fetching getStates", error);
            return null;
        }
    }
    getCounties = async (stateId) => {
        try {
            const url = this.calcBasePath + "state/" + stateId + "/counties";
            const res = await fetch(url, {
                method: "GET"
            });

            if (res.ok) {
                const data = await res.json();
                return data;

            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getCounties", err);
                return null;
            }
        } catch (error) {
            console.log("error while fetching getCounties", error);
            return null;
        }
    }
    getReissueYear = async () => {
        try {
            const url = this.calcBasePath + "reissueyr";
            const res = await fetch(url, {
                method: "GET"
            });

            if (res.ok) {
                const data = await res.json();
                return data;

            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getReissueYear", res);
                return null;
            }
        } catch (error) {
            console.log("error while fetching getReissueYear", error);
            return null;
        }
    }
    getSalesResultDetails = async (payload) => {
        const url = this.calcBasePath + "estimate/sales";

        try {
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getSalesResultDetails", res);
                return null;
            }
        } catch (error) {
            console.log("error while fetching getSalesResultDetails when calculate button clicked", error);
            return null;
        }
    }
    getRefiResultDetails = async (payload) => {
        const url = this.calcBasePath + "estimate/refi";

        try {
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getRefiResultDetails", err);
                return null;
            }
        } catch (error) {
            console.log("error while fetching getRefiResultDetails when calculate button clicked", error);
            return null;
        }
    }
    getEstimateSaleEmail = async (payload) => {
        const url = this.calcBasePath + "estimate/sale/email";
        try {
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getEstimateSaleEmail");
                return null;
            }
        } catch (error) {
            console.log("error while fetching getEstimateSaleEmail when calculate button clicked", error);
            return null;
        }
    }
    getEstimateRefiEmail = async (payload) => {
        const url = this.calcBasePath + "estimate/refi/email";
        try {
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getEstimateRefiEmail", err);
                return null;
            }
        } catch (error) {
            console.log("error while fetching getEstimateRefiEmail when calculate button clicked", error);
            return null;
        }
    }
    getsendTextMessageSaleRefi = async (payload) => {
        const url = this.calcBasePath + "estimate/text";
        try {
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                let err = await res.json();
                if (err.detail)
                    showAlert(err.detail, res.status);
                else
                    showAlert(err.message, res.status);
                console.log("error while fetching getsendTextMessageSaleRefi", err);
                return null;
            }
        } catch (error) {
            console.log("error while fetching getEstimateRefiEmail when calculate button clicked", error);
            return null;
        }
    }
    getSalePrintDetails = async (payload) => {
        const url = this.calcBasePath + "estimate/sale/print";
        try {
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getSalePrintDetails", err)
                return null;
            }
        } catch (error) {
            console.log("error while fetching getSalePrintDetails when calculate button clicked", error);
            return null;
        }
    }
    getRefiPrintDetails = async (payload) => {
        const url = this.calcBasePath + "estimate/refi/print";
        try {
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                let err = await res.json();
                showAlert(err.message, res.status);
                console.log("error while fetching getRefiPrintDetails", err)
                return null;
            }
        } catch (error) {
            console.log("error while fetching getRefiPrintDetails when calculate button clicked", error);
            return null;
        }
    }
}