import "./index.scss";

export const buildCalculatorUI = () => {
    const calcDiv = `
    <div class="igre-calculator">
        <h4>Title / Escrow Fee Estimates</h4>

        <ul id="igre-calc-tab" role="tablist" class="nav nav-tabs igre-calc-tab">
            <li class="nav-item"><a id="igre-sale-tab" data-toggle="tab" href="#igre-sale-tab-content" role="tab"
                    aria-controls="igre-sale-tab-content" aria-selected="true" class="nav-link active">Sale</a>
            </li>
            <li class="nav-item"><a id="igre-refi-tab" data-toggle="tab" href="#igre-refi-tab-content" role="tab"
                    aria-controls="igre-refi-tab-content" aria-selected="false" class="nav-link">Refinance</a>
            </li>
        </ul> <!--#igre-calc-tab -->

        <div id="igre-tabcontent" class="igre-tabcontent tab-content">

            <div id="igre-sale-tab-content" role="tabpanel" aria-labelledby="igre-sale-tab" class="tab-pane active">
                <div class="form-row">

                    <div class="col-6 form-element form-input"><input id="igre-salesprice" name="igre-salesprice"
                            class="form-element-field" onlynumber="true" type="text" value="0.00"><label
                            for="igre-salesprice" class="form-element-label">Sales Price</label>
                    </div>
                    <div class="col-6 form-element form-input"><input id="igre-saleloanamount"
                            name="igre-saleloanamount" class="form-element-field" onlynumber="true" type="text"
                            value="0.00"><label for="igre-saleloanamount" class="form-element-label">Loan
                            Amount</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-md-12 form-element form-select">
                        <select id="igre-salestates" name="igre-salestates" class="form-element-field">
                            <option value=""> Select State </option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-6 form-element form-select">

                        <select id="igre-salecounty" name="igre-salecounty" class="form-element-field">
                            <option value=""> Select County </option>
                        </select>

                    </div>

                    <div class="col-6 form-element form-input"><input id="igre-salezipcode" class="form-element-field"
                            type="text" name="igre-salezipcode" onlynumber="true" value="" maxlength="5"><label
                            for="igre-salezipcode" class="form-element-label">Zip Code
                            (Optional)</label>
                    </div>
                </div>

                <div id="igre-reissueyear" class="form-checkbox text-right igre-nodisplay">
                    <input id="igre-reissueyear-checkbox" type="checkbox" name="igre-reissueyear-checkbox">
                    <label id="igre-reissueyear-label" for="igre-reissueyear-checkbox"></label>
                </div>

                <div id="igre-salebuttons" class="form-row">

                    <button id="igre-saletextmessagebtn" type="button" class="btn btn-primary igre-nodisplay">
                        Text Message </button>
                    <button id="igre-saleprintbtn" type="button" class="btn btn-primary  igre-nodisplay">
                        Print </button>
                    <button id="igre-saleemailbtn" type="button" class="btn btn-primary  igre-nodisplay">
                        Email </button>
                    <button id="igre-salecalculatebtn" type="button" class="btn btn-primary"> Calculate
                    </button>
                    <button id="igre-salestartoverbtn" type="button" class="btn btn-primary">
                        Start Over </button>

                </div> <!--#igre-salebuttons-->

                <div id="igre-calculateSaleResultDetails">
                    ${saleStaticResultDiv()}
                </div> <!--#igre-calculateSaleResultDetails-->

            </div> <!-- #igre-sale-tab-content-->



            <div id="igre-refi-tab-content" role="tabpanel" aria-labelledby="igre-refi-tab" class="tab-pane">
                <div class="form-row">

                    <div class="col-12 col-md-12 form-element form-input"><input id="igre-refiloanamount"
                            name="igre-refiloanamount" class="form-element-field" onlynumber="true" type="text"
                            value="0.00"><label for="igre-refiloanamount" class="form-element-label">Loan
                            Amount</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-md-12 form-element form-select">
                        <select id="igre-refistates" name="igre-refistates" class="form-element-field">
                            <option value=""> Select State </option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-6 form-element form-select">

                        <select id="igre-reficounty" name="igre-reficounty" class="form-element-field">
                            <option value=""> Select County </option>
                        </select>

                    </div>

                    <div class="col-6 form-element form-input"><input id="igre-refizipcode" class="form-element-field"
                            type="text" name="igre-refizipcode" onlynumber="true" value="" maxlength="5"><label
                            for="igre-refizipcode" class="form-element-label">Zip Code
                            (Optional)</label>
                    </div>
                </div>


                <div id="igre-refi-reissueyear" class="form-checkbox text-right igre-nodisplay">
                    <input id="igre-refi-reissueyear-checkbox" type="checkbox" name="igre-refi-reissueyear-checkbox">
                    <label id="igre-refi-reissueyear-label" for="igre-refi-reissueyear-checkbox"></label>
                </div>
                <div id="igre-refibuttons" class="form-row">
                    <button id="igre-refitextmessagebtn" type="button" class="btn btn-primary igre-nodisplay">
                        Text Message </button>
                    <button id="igre-refiprintbtn" type="button" class="btn btn-primary igre-nodisplay">
                        Print </button>
                    <button id="igre-refiemailbtn" type="button" class="btn btn-primary igre-nodisplay">
                        Email </button>
                    <button id="igre-reficalculatebtn" type="button" class="btn btn-primary"> Calculate
                    </button>
                    <button id="igre-refistartoverbtn" type="button" class="btn btn-primary">
                        Start Over </button>
                </div> <!--#igre-refibuttons-->

                <div id="igre-calculateRefiResultDetails">
                    ${refiStaticResultDiv()}
                </div> <!--#igre-calculateRefiResultDetails-->

            </div> <!--#igre-refi-tab-content-->
        </div> <!-- #igre-tabcontent-->

        <div id="igre-emailmodal" class="igre-modal">
            <div class="igre-modal-dialog">
                <div class="igre-modal-content">
                    <div class="igre-modal-header">
                        <h4>Email a Closing Cost Estimate</h4>
                        <span class="igre-close">&times;</span>
                    </div>
                    <div class="igre-modal-body">
                        <div id="igre-modal-email-field" class="form-element form-input">
                            <label class="igre-modal-form-element-label" for="igre-modal-email-field">Email
                                Address</label>
                            <div class="igre-email-id-row">
                                <div id="igre-allmail" class="igre-all-mail">
                                </div>
                                <input id="igre-email" type="text" class="igre-modal-input-text inputText">
                            </div>
                        </div>
                        <div id="igre-emailerror" class="igre-errorlabel igre-nodisplay">
                            <label  id="igre-emailerrormsg" style="color:red;"></label>
                        </div>

                        <div class="form-element form-input">
                            <input id="igre-emailsubject" value="IgniteRE Calculator from First American Title"
                                readonly="readonly" type="text" class="igre-modal-input-text">
                            <label class="igre-modal-form-element-label" for="igre-emailsubject">Subject</label>
                        </div>
                        <div>
                            <label class="igre-modal-form-element-label" for="igre-emailsubject">Note</label>
                            <textarea id="igre-emailnote" maxlength="1000" class="igre-modal-input-text" rows="3" cols="80"></textarea>
                            <label id="igre-textarea-charactorLimit"></label>
                        </div>
                    </div>

                    <div class="igre-modal-footer">
                        <button id="igre-emailcancel" type="button" class="btn btn-primary"> Cancel </button>
                        <button id="igre-emailsend" type="submit" class="btn btn-primary"> Send </button>
                    </div>
                </div>
            </div>
        </div><!--#igre-emailmodal-->
        <div id="igre-mobilemodal" class="igre-modal">
            <div class="igre-modal-dialog">
                <div class="igre-modal-content">
                    <div class="igre-modal-header">
                        <h4>Text Message to Customer</h4>
                        <span class="igre-textclose">&times;</span>
                    </div>
                    <div class="igre-modal-body">
                        <div id="igre-modal-mobile-field" class="form-element form-input">
                            <label id="igre-mobilelabel" class="igre-modal-form-element-label" for="igre-mobile"> To: (press tab to add more
                                mobile numbers), format : 000 - 000 - 0000</label>
                            <div class="igre-mobile-number-id-row">
                                <div id="igre-all-mobilenumber" class="igre-all-mobile-number">
                                </div>
                                <input id="igre-mobile" class="igre-modal-input-text inputText" type="text"
                                    minlength="12" maxlength="18" onlynumber="true" value="" autocomplete="off" />
                            </div>
                        </div>
                        <div id="igre-mobileerror" class="igre-errorlabel igre-nodisplay">
                            <label id="igre-mobileerrormsg" style="color:red;"></label>
                        </div>
                        <div class="form-element form-input">
                        <label class="igre-modal-form-element-label" for="igre-mobilesubject">Message</label>
                            <input id="igre-mobilesubject" maxlength="70" value="IgniteRE Calculator from First American Title" type="text" class="igre-modal-input-text">
                            <label id="igre-text-charactorLimit"></label>
                        </div>
                    </div>
                    <div class="igre-modal-footer">
                        <button id="igre-mobilecancel" type="button" class="btn btn-primary"> Cancel </button>
                        <button id="igre-mobilesend" type="submit" class="btn btn-primary"> Send </button>
                    </div>
                </div>
            </div>
        </div> <!--#igre-mobilemodal-->

        <div id="igre-print" class="igre-modal">
        </div> <!--#igre-print-->

        <div id="igre-loader" class="igre-loader-backdrop igre-nodisplay">
            <div class="igre-loader"></div>
            <div class="igre-loader-text">Please Wait</div>
        </div> <!--#igre-loader-->

        <!-- #start-igre-alert-error-->
        <div id="igre-alert-error" class="igre-alert-error igre-nodisplay">
            <span class="igre-alert-closebtn">&times;</span>
            <div id="igre-alert-errormessage"></div>
        </div>
        <!-- #end-igre-alert-error-->

        <!-- #start-igre-alert-success-->
        <div id="igre-alert-success" class="igre-alert-success igre-nodisplay">
            <span class="igre-alert-success-closebtn">&times;</span>
            <div id="igre-alert-successmsg"></div>
        </div>
        <!-- #end-igre-alert-error-->

    </div> <!-- .igre-calculator -->
  `
    return calcDiv;
}
export const saleStaticResultDiv = () => {
    return `
    <div class="form-row pb-3">
        <div class="col-12 col-md-3 text-left"> Owners Policy</div>
        <div class="col-12 col-md-4 text-left"> $ 0.00 Seller</div>
        <div class="col-12 col-md-5 text-left"> </div>
    </div>
    <div class="form-row pb-3">
        <div class="col-12 col-md-3 text-left"> Lenders Policy </div>
        <div class="col-12 col-md-4 text-left"> $ 0.00 Buyer</div>
        <div class="col-12 col-md-5 text-left"> </div>
    </div>
    <div class="form-row pb-3">
        <div class="col-12 col-md-3 text-left"> Total Escrow Fee </div>
        <div class="col-12 col-md-4 text-left"> $ 0.00 Buyer &nbsp;&nbsp;&nbsp; $ 0.00 Seller </div>
        <div class="col-12 col-md-5 text-left"> </div>
    </div>
    `
}
export const refiStaticResultDiv = () => {
    return `
    <div class="form-row pb-3">
        <div class="col-12 col-md-3 text-left"> Title Insurance</div>
        <div class="col-12 col-md-4 text-left"> $ 0.00</div>
        <div class="col-12 col-md-5 text-left"> </div>
    </div>
    <div class="form-row pb-3">
        <div class="col-12 col-md-3 text-left"> Total Escrow Fee </div>
        <div class="col-12 col-md-4 text-left"> $ 0.00</div>
        <div class="col-12 col-md-5 text-left"> </div>
    </div>
    `
}
