import { buildCalculatorUI } from '../ui';
import { IGRECalcService } from '../service/igre-calc-service';
import { salestartoverAllfields, refistartoverAllfields, resetEmailTextModal, emailFieldFocusOutEvent, mobileNumberFieldFocusOutEvent, hideAlert, saleReset, refiReset, hideerrorLabel } from '../utils/calc-util';
import { saleAllaActionsValidate, refiAllaActionsValidate } from '../validate/validate';

const igreCalcService = new IGRECalcService();
export const initCalculator = async (config) => {
    const calcHtml = buildCalculatorUI();
    document.getElementById(config.parentId).innerHTML = calcHtml;
    initIgreCalcTabs();
    await igreCalcService.populateStates();
    await igreCalcService.getReissueDetails();
    attachActionListeners();
    selectDefaultState(config.defaultStateId);
}

const attachActionListeners = () => {

    //---- Start States and County Populate---//
    let salestateDD = document.getElementById("igre-salestates");
    let refistateDD = document.getElementById("igre-refistates");
    let saleCountyDD = document.getElementById("igre-salecounty");
    let refiCountyDD = document.getElementById("igre-reficounty");
    let saleEmailPopUpButton = document.getElementById("igre-saleemailbtn");
    let refiEmailPopUpButton = document.getElementById("igre-refiemailbtn");
    salestateDD.addEventListener("change", function () {
        igreCalcService.populateSaleCounties();
        hideAlert();
        if (saleEmailPopUpButton.style.display == "block") {
            saleReset();
        }
    });
    saleCountyDD.addEventListener("change", function () {
        if (saleEmailPopUpButton.style.display == "block") {
            saleReset();
        }
        hideAlert();
        igreCalcService.setReissueYear();
    });
    refiCountyDD.addEventListener("change", function () {
        if (refiEmailPopUpButton.style.display == "block") {
            refiReset();
        }
        hideAlert();
        igreCalcService.setRefinanceReissueYear();
    });
    refistateDD.addEventListener("change", function () {
        igreCalcService.populateRefiCounties();
        hideAlert();
        if (refiEmailPopUpButton.style.display == "block") {
            refiReset();
        }
    });
    //---- End States and County Populate---//

    //---- Start Calculate and StartOver Buttons Actions for Sale and Refinance---//
    let saleCalcButton = document.getElementById("igre-salecalculatebtn");
    saleCalcButton.addEventListener("click", function () {
        if (saleAllaActionsValidate()) {
            hideAlert();
            igreCalcService.calculateSale();
        }
    });
    let refiCalcButton = document.getElementById("igre-reficalculatebtn");
    refiCalcButton.addEventListener("click", function () {
        if (refiAllaActionsValidate()) {
            igreCalcService.calculateRefinance();
            hideAlert();
        }
    });
    let saleStartOverButton = document.getElementById("igre-salestartoverbtn");
    saleStartOverButton.addEventListener("click", function () {
        salestartoverAllfields();
        hideAlert();
    });
    let refiStartOverButton = document.getElementById("igre-refistartoverbtn");
    refiStartOverButton.addEventListener("click", function () {
        refistartoverAllfields();
        hideAlert();
    });
    //---- End Calculate and StartOver Buttons Actions for Sale and Refinance---//

    //---- Start Sale an Refinance Tab Actions ---//
    let saleTabButton = document.getElementById("igre-sale-tab");
    let refiTabButton = document.getElementById("igre-refi-tab");
    saleTabButton.value = "sale";
    refiTabButton.value = "";
    saleTabButton.addEventListener("click", function (event) {
        saleTabButton.value = "sale";
        refiTabButton.value = "";
        refistartoverAllfields();
        event.preventDefault();
        hideAlert();
    });
    refiTabButton.addEventListener("click", function (event) {
        saleTabButton.value = "";
        refiTabButton.value = "refi";
        salestartoverAllfields();
        event.preventDefault();
        hideAlert();
    });
    //---- End Sale an Refinance Tab Actions ---//

    //---- Start Email Popup for Sale and Refinance---//
    let emailModalPopUp = document.getElementById("igre-emailmodal");
    saleEmailPopUpButton.addEventListener("click", function () {
        if (saleAllaActionsValidate()) {
            emailModalPopUp.style.display = "block";
            hideAlert();
        }
    });
    refiEmailPopUpButton.addEventListener("click", function () {
        if (refiAllaActionsValidate()) {
            emailModalPopUp.style.display = "block";
            hideAlert();
        }
    });
    let spanClosePopUp = document.getElementsByClassName("igre-close")[0];
    let spanCloseTextPopUp = document.getElementsByClassName("igre-textclose")[0];
    let spanCloseErrorPopUp = document.getElementsByClassName("igre-alert-closebtn")[0];
    let spanCloseSuccessPopUp = document.getElementsByClassName("igre-alert-success-closebtn")[0];

    spanClosePopUp.addEventListener("click", function () {
        resetEmailTextModal();
        emailModalPopUp.style.display = "none";
        hideAlert();
    });
    spanCloseTextPopUp.addEventListener("click", function () {
        resetEmailTextModal();
        mobileModalPopUp.style.display = "none";
        hideAlert();
    });
    spanCloseErrorPopUp.addEventListener("click", function () {
        hideAlert();
    });
    spanCloseSuccessPopUp.addEventListener("click", function () {
        hideAlert();
    });
    let cancelEmailPopUp = document.getElementById("igre-emailcancel");
    cancelEmailPopUp.addEventListener("click", function () {
        resetEmailTextModal();
        emailModalPopUp.style.display = "none";
        hideAlert();
    });
    let sendEmailButton = document.getElementById("igre-emailsend");
    sendEmailButton.addEventListener("click", function () {
        igreCalcService.sendEmailSaleRefinance();
        hideAlert();
    });
    //---- End Email Popup for Sale and Refinance---//

    //---- Start Email input -----//
    let emailField = document.getElementById("igre-email");
    emailField.addEventListener("focusout", emailFieldFocusOutEvent);
    emailField.addEventListener("keypress", function (e) {
        if (e.key === 'Enter') {
            emailFieldFocusOutEvent(e);
        }
    });
    let allmobileNumber = document.getElementById("igre-all-mobilenumber");
    allmobileNumber.addEventListener("click", function () {
        hideerrorLabel();

    });
    let allEmailAddress = document.getElementById("igre-allmail");
    allEmailAddress.addEventListener("click", function () {
        hideerrorLabel();
    });
    let mobileNumberField = document.getElementById("igre-mobile");
    mobileNumberField.addEventListener("keypress", function (e) {

        if (e.key === 'Enter') {
            mobileNumberFieldFocusOutEvent(e);
        } else {
            allowOnlyNumbers(e);
        }
    });
    mobileNumberField.addEventListener("focusout", mobileNumberFieldFocusOutEvent);
    //---- End Text message input -----//

    //---- Start Text Message Popup for Sale and Refinance---//
    let mobileModalPopUp = document.getElementById("igre-mobilemodal");
    let saletextMsgbutton = document.getElementById("igre-saletextmessagebtn");
    let refitextMsgbutton = document.getElementById("igre-refitextmessagebtn");
    saletextMsgbutton.addEventListener("click", function () {
        if (saleAllaActionsValidate()) {
            mobileModalPopUp.style.display = "block";
            hideAlert();
        }
    });
    refitextMsgbutton.addEventListener("click", function () {
        if (refiAllaActionsValidate()) {
            mobileModalPopUp.style.display = "block";
            hideAlert();
        }
    });
    let cancelMobilePopUp = document.getElementById("igre-mobilecancel");
    cancelMobilePopUp.addEventListener("click", function () {
        resetEmailTextModal();
        mobileModalPopUp.style.display = "none";
        hideAlert();
    });
    let sendTextButton = document.getElementById("igre-mobilesend");
    sendTextButton.addEventListener("click", function () {
        igreCalcService.sendTextMsgSaleRefinance();
        hideAlert();
    });
    //---- End Text Message Popup for Sale and Refinance---//

    //---- Start Print for Sale and Refinance---//
    let salePrintButton = document.getElementById("igre-saleprintbtn");
    salePrintButton.addEventListener("click", function () {
        if (saleAllaActionsValidate()) {
            igreCalcService.getSalePrintDetails();
            hideAlert();
        }
    });
    let refiPrintButton = document.getElementById("igre-refiprintbtn");
    refiPrintButton.addEventListener("click", function () {
        if (refiAllaActionsValidate()) {
            igreCalcService.getRefiPrintDetails();
            hideAlert();
        }
    });
    //---- End Print for Sale and Refinance---//

    //---- Start Zip Code Hide Alert -----//

    let saleZipcode = document.getElementById("igre-salezipcode");
    let refiZipcode = document.getElementById("igre-refizipcode");
    saleZipcode.addEventListener("change", function () {
        if (saleEmailPopUpButton.style.display == "block") {
            saleReset();
        }
        hideAlert();
    });
    refiZipcode.addEventListener("change", function () {
        if (refiEmailPopUpButton.style.display == "block") {
            refiReset();
        }
        hideAlert();
    });
    let reIssueYearCheckBox = document.getElementById("igre-reissueyear-checkbox");
    reIssueYearCheckBox.addEventListener("change", function () {
        if (saleEmailPopUpButton.style.display == "block") {
            saleReset();
        }
        hideAlert();
    });
    let reIssueYearRefiCheckBox = document.getElementById("igre-refi-reissueyear-checkbox");
    reIssueYearRefiCheckBox.addEventListener("change", function () {
        if (refiEmailPopUpButton.style.display == "block") {
            refiReset();
        }
        hideAlert();
    });
    saleZipcode.addEventListener("keypress", allowOnlyNumbers);
    refiZipcode.addEventListener("keypress", allowOnlyNumbers);
    //---- End Zip Code Hide Alert -----//


    //---- Start Input validation: Sales Price -----//
    let saleAmtIPField = document.getElementById("igre-salesprice");
    saleAmtIPField.addEventListener("click", function () {
        this.select();
        hideAlert();
    });
    saleAmtIPField.addEventListener("change", function () {
        if (saleEmailPopUpButton.style.display == "block") {
            saleReset();
        }
    });
    saleAmtIPField.addEventListener("keypress", allowOnlyNumbers);
    saleAmtIPField.addEventListener("keyup", formatNumbers);
    saleAmtIPField.addEventListener("focusout", convertNumberToDecimal);
    //---- End Input validation: Sales Price -----//

    //---- Start Input validation: sale Loan Amount -----//
    let saleloanAmtIPField = document.getElementById("igre-saleloanamount");
    saleloanAmtIPField.addEventListener("click", function () {
        this.select();
        hideAlert();
    });
    saleloanAmtIPField.addEventListener("change", function () {
        if (saleEmailPopUpButton.style.display == "block") {
            saleReset();
        }
    });
    saleloanAmtIPField.addEventListener("keypress", allowOnlyNumbers);
    saleloanAmtIPField.addEventListener("keyup", formatNumbers);
    saleloanAmtIPField.addEventListener("focusout", convertNumberToDecimal);
    //---- End Input validation: sale Loan Amount -----//

    //---- Start Input validation: sale Loan Amount -----//
    let refiloanAmtIPField = document.getElementById("igre-refiloanamount");
    refiloanAmtIPField.addEventListener("click", function () {
        this.select();
        hideAlert();
    });
    refiloanAmtIPField.addEventListener("change", function () {
        if (refiEmailPopUpButton.style.display == "block") {
            refiReset();
        }
    });
    refiloanAmtIPField.addEventListener("keypress", allowOnlyNumbers);
    refiloanAmtIPField.addEventListener("keyup", formatNumbers);
    refiloanAmtIPField.addEventListener("focusout", convertNumberToDecimal);
    //---- End Input validation: sale Loan Amount -----//

    let textMessageField = document.getElementById("igre-mobilesubject");
    textMessageField.addEventListener("keyup", textCharactorCount);
    let texAreatNoteField = document.getElementById("igre-emailnote");
    texAreatNoteField.addEventListener("keyup", textAreaCharactorCount);
}

const allowOnlyNumbers = (event) => {
    if (!(event.key >= 0 && event.key <= 9)) {
        event.stopPropagation();
        event.preventDefault();
    }
}

const formatNumbers = (event) => {
    event.stopPropagation();
    let value = event.target.value?.replaceAll(',', '');
    event.target.value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const convertNumberToDecimal = (event) => {
    event.stopPropagation();
    let value = event.target.value?.replaceAll(',', '');
    if (value >= 100000000) {
        value = 0;
    }
    value = (value / 1).toFixed(2);
    event.target.value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const textCharactorCount = (event) => {
    let maximumLength = 70;
    let stringLength = event.target.value.trim().length;
    let remainCharacters = (maximumLength - stringLength);

    if (stringLength == 0) {
        stringLength = "";
        document.getElementById("igre-text-charactorLimit").innerHTML = '<span style="color: #EC1F1F;">Please enter the message</span>';
    } else {
        document.getElementById("igre-text-charactorLimit").innerHTML = remainCharacters + ' characters remaining';
    }
}
const textAreaCharactorCount = (event) => {

    let maximumLength = 1000;
    let stringLength = event.target.value.length;
    let remainCharacters = (maximumLength - stringLength);

    if (remainCharacters < 0) {
        document.getElementById("igre-textarea-charactorLimit").innerHTML = '<span style="color: #EC1F1F;">You have exceeded the limit of ' + maximumLength + ' characters</span>';
    } else {
        document.getElementById("igre-textarea-charactorLimit").innerHTML = remainCharacters + ' characters remaining';
    }
}
const initIgreCalcTabs = function () {
    const tabs = document.querySelectorAll("#igre-calc-tab>li>a"),
        panes = document.querySelectorAll("#igre-tabcontent>div")
    for (const element of tabs)
        element.addEventListener("click", function (event) {
            for (const element of tabs) element.classList.remove("active");

            for (const element of panes) element.classList.remove("show", "active");

            const tab = this.classList, pane = document.getElementById(this.getAttribute("aria-controls")).classList

            tab.add("active")
            pane.add("show", "active")
        })
}

const selectDefaultState = function (defaultStateId) {
    let saleStateDD = document.getElementById("igre-salestates");
    let refiStateDD = document.getElementById("igre-refistates");
    if (defaultStateId) {
        defaultStateId = defaultStateId.toString();
        for (let i = 0; i < saleStateDD.options.length; i++) {
            if (saleStateDD.options[i].value === defaultStateId) {
                saleStateDD.selectedIndex = i;
                refiStateDD.selectedIndex = i;
                igreCalcService.populateSaleCounties();
                igreCalcService.populateRefiCounties();
                break;
            }
        }
    }
}