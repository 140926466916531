

import { showAlert } from "../utils/calc-util";

export const saleAllaActionsValidate = () => {
    let salestateDD = document.getElementById("igre-salestates");
    let salecountyDD = document.getElementById("igre-salecounty");
    let salepricetext = document.getElementById("igre-salesprice")
    let saleloanAmounttext = document.getElementById("igre-saleloanamount")
    if (salestateDD.selectedIndex != 0 && salecountyDD.selectedIndex != 0 && salepricetext.value != "0.00") {

        return true;
    }
    showAlert("Please fill all mandatory fields", "inputfields");
    return false;
}
export const refiAllaActionsValidate = () => {
    let refistateDD = document.getElementById("igre-refistates");
    let reficountyDD = document.getElementById("igre-reficounty");
    let refiloanAmounttext = document.getElementById("igre-refiloanamount")
    if (refistateDD.selectedIndex != 0 && reficountyDD.selectedIndex != 0 && refiloanAmounttext.value != "0.00") {

        return true;
    }
    showAlert("Please fill all mandatory fields", "inputfields");
    return false;
}
export const getSaleEmailPayload = () => {
    let salestateDD = document.getElementById("igre-salestates");
    let salecountyDD = document.getElementById("igre-salecounty");
    let salepricetext = document.getElementById("igre-salesprice")
    let saleloanAmounttext = document.getElementById("igre-saleloanamount")
    let saleZipcodetext = document.getElementById("igre-salezipcode")
    //let saleEmail = document.getElementById("igre-email");
    let saleEmailList = [];
    let saleEmailSubject = document.getElementById("igre-emailsubject");
    let saleEmailNote = document.getElementById("igre-emailnote");
    let reIssueYearCheckBox = document.getElementById("igre-reissueyear-checkbox")
    if (reIssueYearCheckBox.checked)
        reIssueYearCheckBox = "1"
    else
        reIssueYearCheckBox = "0"
    document.querySelectorAll('.igre-all-mail .igre-email-ids .igre-cancel-emailto').forEach((email) => {
        saleEmailList.push(email.innerText);
    });
    let payload = {
        "salePrice": salepricetext.value.replaceAll(',', ''),
        "loanAmount": saleloanAmounttext.value.replaceAll(',', ''),
        "stateName": salestateDD.options[salestateDD.selectedIndex].text,
        "countyName": salecountyDD.options[salecountyDD.selectedIndex].text,
        "reIssueYear": reIssueYearCheckBox,
        "postalCode": saleZipcodetext.value,
        "actionType": "email",
        "email": saleEmailList,
        "subject": saleEmailSubject.value,
        "message": saleEmailNote.value.trim()
    }
    return payload;
}
export const getRefiEmailPayload = () => {

    //let refiEmail = document.getElementById("igre-email");
    let refiEmailList = [];
    let refiEmailSubject = document.getElementById("igre-emailsubject");
    let refiEmailNote = document.getElementById("igre-emailnote");
    let refistateDD = document.getElementById("igre-refistates");
    let reficountyDD = document.getElementById("igre-reficounty");
    let refiloanAmounttext = document.getElementById("igre-refiloanamount")
    let refiZipcodetext = document.getElementById("igre-refizipcode")
    let reIssueYearRefiCheckBox = document.getElementById("igre-refi-reissueyear-checkbox")
    if (reIssueYearRefiCheckBox.checked)
        reIssueYearRefiCheckBox = "1"
    else
        reIssueYearRefiCheckBox = "0"
    document.querySelectorAll('.igre-all-mail .igre-email-ids .igre-cancel-emailto').forEach((email, index) => {
        refiEmailList.push(email.innerText);
    });
    let payload = {
        "loanAmount": refiloanAmounttext.value.replaceAll(',', ''),
        "stateName": refistateDD.options[refistateDD.selectedIndex].text,
        "countyName": reficountyDD.options[reficountyDD.selectedIndex].text,
        "reIssueYear": reIssueYearRefiCheckBox,
        "postalCode": refiZipcodetext.value,
        "actionType": "email",
        "email": refiEmailList,
        "subject": refiEmailSubject.value,
        "message": refiEmailNote.value.trim()
    }
    return payload;
}
export const getSaleResultDivPayload = () => {
    let salestateDD = document.getElementById("igre-salestates");
    let salecountyDD = document.getElementById("igre-salecounty");
    let salepricetext = document.getElementById("igre-salesprice")
    let saleloanAmounttext = document.getElementById("igre-saleloanamount")
    let saleZipcodetext = document.getElementById("igre-salezipcode")
    let reIssueYearCheckBox = document.getElementById("igre-reissueyear-checkbox")
    if (reIssueYearCheckBox.checked)
        reIssueYearCheckBox = "1"
    else
        reIssueYearCheckBox = "0"
    let payload = {
        "salePrice": salepricetext.value.replaceAll(',', ''),
        "loanAmount": saleloanAmounttext.value.replaceAll(',', ''),
        "stateName": salestateDD.options[salestateDD.selectedIndex].text,
        "countyName": salecountyDD.options[salecountyDD.selectedIndex].text,
        "reIssueYear": reIssueYearCheckBox,
        "postalCode": saleZipcodetext.value
    }
    return payload;
}
export const getRefiResultDivPayload = () => {
    let stateDD = document.getElementById("igre-refistates");
    let countyDD = document.getElementById("igre-reficounty");
    let refiloanAmounttext = document.getElementById("igre-refiloanamount")
    let refiZipcodetext = document.getElementById("igre-refizipcode")
    let reIssueYearRefiCheckBox = document.getElementById("igre-refi-reissueyear-checkbox")
    if (reIssueYearRefiCheckBox.checked)
        reIssueYearRefiCheckBox = "1"
    else
        reIssueYearRefiCheckBox = "0"
    let payload = {
        "loanAmount": refiloanAmounttext.value.replaceAll(',', ''),
        "stateName": stateDD.options[stateDD.selectedIndex].text,
        "countyName": countyDD.options[countyDD.selectedIndex].text,
        "reIssueYear": reIssueYearRefiCheckBox,
        "postalCode": refiZipcodetext.value
    }
    return payload;
}
export const getSaleRefiTextPayload = () => {
    let saleTabButton = document.getElementById("igre-sale-tab");
    let refiTabButton = document.getElementById("igre-refi-tab");
    let salestateDD = document.getElementById("igre-salestates");
    let salecountyDD = document.getElementById("igre-salecounty");
    let salepricetext = document.getElementById("igre-salesprice")
    let saleloanAmounttext = document.getElementById("igre-saleloanamount")
    let saleZipcodetext = document.getElementById("igre-salezipcode")
    //let mobile = document.getElementById("igre-mobile");
    let phoneNumberList = [];
    let textMessage = document.getElementById("igre-mobilesubject");
    let refistateDD = document.getElementById("igre-refistates");
    let reficountyDD = document.getElementById("igre-reficounty");
    let refiloanAmounttext = document.getElementById("igre-refiloanamount")
    let refiZipcodetext = document.getElementById("igre-refizipcode")
    let reIssueYearCheckBox = document.getElementById("igre-reissueyear-checkbox")
    if (reIssueYearCheckBox.checked)
        reIssueYearCheckBox = "1"
    else
        reIssueYearCheckBox = "0"
    let reIssueYearRefiCheckBox = document.getElementById("igre-refi-reissueyear-checkbox")
    if (reIssueYearRefiCheckBox.checked)
        reIssueYearRefiCheckBox = "1"
    else
        reIssueYearRefiCheckBox = "0"
    let payload = "";
    document.querySelectorAll('.igre-all-mobile-number .igre-mobile-number-ids .igre-cancel-mobile-number').forEach((number, index) => {
        phoneNumberList.push(number.innerText);
    });
    if (saleTabButton.value == "sale" && refiTabButton.value == "") {
        payload = {
            "salePrice": salepricetext.value.replaceAll(',', ''),
            "loanAmount": saleloanAmounttext.value.replaceAll(',', ''),
            "stateName": salestateDD.options[salestateDD.selectedIndex].text,
            "countyName": salecountyDD.options[salecountyDD.selectedIndex].text,
            "reIssueYear": reIssueYearCheckBox,
            "postalCode": saleZipcodetext.value,
            "loanType": "Sale",
            "toPhoneNumber": phoneNumberList, //Phone format needs to be maintained in the same form.
            "toMessage": textMessage.value.trim()
        }
    }
    else if ((saleTabButton.value == "" && refiTabButton.value == "refi")) {
        payload = {
            "salePrice": "0.00",
            "loanAmount": refiloanAmounttext.value.replaceAll(',', ''),
            "stateName": refistateDD.options[refistateDD.selectedIndex].text,
            "countyName": reficountyDD.options[reficountyDD.selectedIndex].text,
            "reIssueYear": reIssueYearRefiCheckBox,
            "postalCode": refiZipcodetext.value,
            "loanType": "Refi",
            "toPhoneNumber": phoneNumberList, //Phone format needs to be maintained in the same form.
            "toMessage": textMessage.value.trim()
        }
    }
    return payload;
}
export const getSalePrintPayload = () => {
    let salestateDD = document.getElementById("igre-salestates");
    let salecountyDD = document.getElementById("igre-salecounty");
    let salepricetext = document.getElementById("igre-salesprice")
    let saleloanAmounttext = document.getElementById("igre-saleloanamount")
    let saleZipcodetext = document.getElementById("igre-salezipcode")
    let reIssueYearCheckBox = document.getElementById("igre-reissueyear-checkbox")
    if (reIssueYearCheckBox.checked)
        reIssueYearCheckBox = "1"
    else
        reIssueYearCheckBox = "0"
    let payload = {
        "salePrice": salepricetext.value.replaceAll(',', ''),
        "loanAmount": saleloanAmounttext.value.replaceAll(',', ''),
        "stateName": salestateDD.options[salestateDD.selectedIndex].text,
        "countyName": salecountyDD.options[salecountyDD.selectedIndex].text,
        "reIssueYear": reIssueYearCheckBox,
        "postalCode": saleZipcodetext.value,
        "actionType": "print"
    }
    return payload;
}
export const getRefiPrintPayload = () => {
    let refistateDD = document.getElementById("igre-refistates");
    let reficountyDD = document.getElementById("igre-reficounty");
    let refiloanAmounttext = document.getElementById("igre-refiloanamount")
    let refiZipcodetext = document.getElementById("igre-refizipcode")
    let reIssueYearRefiCheckBox = document.getElementById("igre-refi-reissueyear-checkbox")
    if (reIssueYearRefiCheckBox.checked)
        reIssueYearRefiCheckBox = "1"
    else
        reIssueYearRefiCheckBox = "0"
    let payload = {

        "loanAmount": refiloanAmounttext.value.replaceAll(',', ''),
        "stateName": refistateDD.options[refistateDD.selectedIndex].text,
        "countyName": reficountyDD.options[reficountyDD.selectedIndex].text,
        "reIssueYear": reIssueYearRefiCheckBox,
        "postalCode": refiZipcodetext.value,
        "actionType": "print"
    }
    return payload;
}