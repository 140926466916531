import { saleStaticResultDiv, refiStaticResultDiv } from '../ui'

export const statesDynamicHTML = (states) => {
    return `<option value=""> Select State </option>
    ${states ? states.filter(item => item)
            .map((item) => `<option value = "${item.id}">${item.statename}</option>`).join("")
            : ""}`
}

export const countyDynamicHTML = (counties) => {
    return `<option value="">Select County </option>
    ${counties.data ? counties.data.filter(item => item)
            .map((item) => `<option value = "${item.id}">${item.countyname}</option>`)
            : ""}`
}

export const showHideAddlSaleActions = (actionsShowHide) => {
    if (actionsShowHide) {
        document.getElementById('igre-saletextmessagebtn').style.display = 'block';
        document.getElementById('igre-saleprintbtn').style.display = 'block';
        document.getElementById('igre-saleemailbtn').style.display = 'block';
    }
    else {
        document.getElementById('igre-saletextmessagebtn').style.display = 'none';
        document.getElementById('igre-saleprintbtn').style.display = 'none';
        document.getElementById('igre-saleemailbtn').style.display = 'none';
    }
}
export const showHideAddlRefiActions = (actionsShowHide) => {
    if (actionsShowHide) {
        document.getElementById('igre-refitextmessagebtn').style.display = 'block';
        document.getElementById('igre-refiprintbtn').style.display = 'block';
        document.getElementById('igre-refiemailbtn').style.display = 'block';
    }
    else {
        document.getElementById('igre-refitextmessagebtn').style.display = 'none';
        document.getElementById('igre-refiprintbtn').style.display = 'none';
        document.getElementById('igre-refiemailbtn').style.display = 'none';
    }
}
export const hideerrorLabel = () => {
    let errorMobileNumberLabel = document.getElementById("igre-mobileerrormsg");
    let errorMobileNumber = document.getElementById("igre-mobileerror");
    errorMobileNumber.classList.add("igre-nodisplay");
    errorMobileNumberLabel.innerHTML = "";
    let errorEmailLabel = document.getElementById("igre-emailerrormsg");
    let errorEmail = document.getElementById("igre-emailerror");
    errorEmail.classList.add("igre-nodisplay");
    errorEmailLabel.innerHTML = "";

}
export const showerrorLabel = () => {
    let errorMobileNumber = document.getElementById("igre-mobileerror");
    errorMobileNumber.classList.remove("igre-nodisplay");
    let errorEmail = document.getElementById("igre-emailerror");
    errorEmail.classList.remove("igre-nodisplay");
}

export const showLoader = () => {
    let loaderElement = document.getElementById('igre-loader');
    loaderElement.classList.remove("igre-nodisplay");
}
export const hideLoader = () => {
    let loaderElement = document.getElementById('igre-loader');
    loaderElement.classList.add("igre-nodisplay");
}
export const showAlert = (message, res) => {
    let alertError = document.getElementById('igre-alert-error');
    alertError.classList.remove("igre-nodisplay");
    let alertErrorMessage = document.getElementById("igre-alert-errormessage");
    if (res == 422 || res == 400) {
        alertErrorMessage.innerHTML = message;
    }
    else if (res == "inputfields") {
        alertErrorMessage.innerHTML = message;
    }
    else {
        alertErrorMessage.innerHTML = "Something went wrong!, Please try again later";
    }
}
export const hideAlert = () => {
    let errorMessage = document.getElementById('igre-alert-error');
    let successMessage = document.getElementById('igre-alert-success');
    errorMessage.classList.add("igre-nodisplay");
    successMessage.classList.add("igre-nodisplay");
}
export const showSuccessAlert = (res) => {
    let alertError = document.getElementById('igre-alert-success');
    alertError.classList.remove("igre-nodisplay");
    let alertErrorMessage = document.getElementById("igre-alert-successmsg");
    alertErrorMessage.innerHTML = res;
    setTimeout(hideSuccessAlert, 10000)
}
export const hideSuccessAlert = () => {
    let errorMessage = document.getElementById('igre-alert-success');
    errorMessage.classList.add("igre-nodisplay");
}
export const resetEmailTextModal = () => {
    let email = document.getElementById("igre-email")
    let mobile = document.getElementById("igre-mobile")
    let saleEmailNoteEl = document.getElementById("igre-emailnote")
    let saleRefiEmail = document.getElementById("igre-allmail");
    let saleRefiMobileNumber = document.getElementById("igre-all-mobilenumber");
    let textMessageField = document.getElementById("igre-mobilesubject");
    let textCharacterLimit = document.getElementById("igre-text-charactorLimit");
    let textAreaCharactorLimit = document.getElementById("igre-textarea-charactorLimit");
    email.value = "";
    mobile.value = "";
    saleRefiEmail.innerHTML = "";
    saleEmailNoteEl.value = "";
    saleRefiMobileNumber.innerHTML = "";
    textMessageField.value = "IgniteRE Calculator from First American Title";
    textCharacterLimit.innerHTML = "";
    textAreaCharactorLimit.innerHTML = "";
    hideerrorLabel();
}
export const salestartoverAllfields = () => {
    let saleStateSelected = document.getElementById("igre-salestates")
    let saleCountySelected = document.getElementById("igre-salecounty")
    let salepricetext = document.getElementById("igre-salesprice")
    let saleloanAmounttext = document.getElementById("igre-saleloanamount")
    let saleZipcodetext = document.getElementById("igre-salezipcode")
    let calculateSaleResultDetails = document.getElementById("igre-calculateSaleResultDetails");
    let reIssueYearCheckBox = document.getElementById("igre-reissueyear-checkbox")
    let reIssueYearRefiCheckBox = document.getElementById("igre-refi-reissueyear-checkbox")

    calculateSaleResultDetails.innerHTML = saleStaticResultDiv();
    saleStateSelected.selectedIndex = 0
    saleCountySelected.selectedIndex = 0
    salepricetext.value = "0.00";
    saleloanAmounttext.value = "0.00";
    saleZipcodetext.value = "";
    while (saleCountySelected.options.length > 0) {
        saleCountySelected.remove(0);
    }
    saleCountySelected.innerHTML = `<option value="">Select County </option>`
    reIssueYearCheckBox.checked = false;
    reIssueYearRefiCheckBox.checked = false;
    let actionsShowHide = false;
    showHideAddlSaleActions(actionsShowHide);

    let reIssueYearId = document.getElementById("igre-reissueyear");
    reIssueYearId.style.display = 'none';
}
export const refistartoverAllfields = () => {
    let refiStateSelected = document.getElementById("igre-refistates")
    let refiCountySelected = document.getElementById("igre-reficounty")
    let refiloanAmounttext = document.getElementById("igre-refiloanamount")
    let refiZipcodetext = document.getElementById("igre-refizipcode")
    let reIssueYearRefiCheckBox = document.getElementById("igre-refi-reissueyear-checkbox")
    let calculateRefiResultDetails = document.getElementById("igre-calculateRefiResultDetails");
    calculateRefiResultDetails.innerHTML = refiStaticResultDiv();
    refiStateSelected.selectedIndex = 0;
    refiCountySelected.selectedIndex = 0;
    refiloanAmounttext.value = "0.00";
    refiZipcodetext.value = "";

    while (refiCountySelected.options.length > 0) {
        refiCountySelected.remove(0);
    }
    refiCountySelected.innerHTML = `<option value="">Select County </option>`
    reIssueYearRefiCheckBox.checked = false;
    let actionsShowHide = false;
    showHideAddlRefiActions(actionsShowHide);
}

export const emailFieldFocusOutEvent = (e) => {
    let emailAddress = e.target.value;
    let emailerrormsg = document.getElementById("igre-emailerrormsg");
    if (e.target.value !== "") {
        if (!validateEmail(emailAddress)) {
            showerrorLabel();
            emailerrormsg.innerHTML = "Invalid email address: " + '"' + emailAddress + '". Expected format x@xx.xx';
        } else {
            let div = document.getElementsByClassName('igre-email-id-row');
            let hei = div[0].style.height.replace('px', '')
            div[0].style.height = parseInt(hei) + 8 + 'px';
            document.getElementsByClassName('igre-all-mail')[0].appendChild(createEmailNode(emailAddress));
            e.target.value = "";
            hideerrorLabel();
        }
    }
    else {
        hideerrorLabel();
    }
    e.stopPropagation();
    e.preventDefault();
}

export const validateEmail = (email) => {
    if (email != "") {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return (email.match(regex) !== null);
    }
}

export const createEmailNode = (email) => {
    const spanNode = document.createElement("span");
    spanNode.className = "igre-email-ids";
    const aNode = document.createElement("a");
    aNode.className = "igre-cancel-emailto";
    aNode.addEventListener('click', function () {
        let res = this.parentNode;
        res.parentNode.removeChild(res);
        let div = document.getElementsByClassName('igre-email-id-row');
        let hei = div[0].style.height.replace('px', '')
        div[0].style.height = parseInt(hei) - 8 + 'px';
    })
    aNode.innerHTML = email + '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="17.198" viewBox="0 0 17.199 17.198"><g id="Close" transform="translate(-410.288 -8330.738) rotate(45)"><path id="Path_513" data-name="Path 513" d="M6192.224,5603.756v-2.035c0-.342,0-.342-.353-.342-1.389,0-2.778,0-4.167,0a.773.773,0,1,1,.012-1.546q2.024,0,4.048,0a1.72,1.72,0,0,1,.213,0c.2.026.251-.071.251-.26-.006-1.4,0-2.793,0-4.189a1,1,0,0,1,.033-.328.779.779,0,0,1,.877-.522.751.751,0,0,1,.637.767c0,1.381,0,2.761,0,4.142,0,.389,0,.389.376.389,1.389,0,2.778,0,4.167,0a.75.75,0,0,1,.758.647.767.767,0,0,1-.559.872,1.315,1.315,0,0,1-.329.026q-2.024,0-4.048,0c-.365,0-.365,0-.365.377q0,2.058,0,4.118a.756.756,0,0,1-.815.809.748.748,0,0,1-.731-.773c0-.718,0-1.436,0-2.154Z" transform="translate(0)" fill="#fb0000"></path> </g></svg>';
    spanNode.appendChild(aNode);
    return spanNode;
}
export const mobileNumberFieldFocusOutEvent = (e) => {
    let mobileerrormsg = document.getElementById("igre-mobileerrormsg");
    let mobileNumber = e.target.value?.replace(/\D/g, "");
    if (e.target.value !== "") {
        if (!validateMobileNumber(mobileNumber)) {
            showerrorLabel();
            mobileerrormsg.innerHTML = "Invalid mobile number: " + '"' + mobileNumber + '". 10 - 12 digit number is expected';
        } else {
            mobileNumber = `${mobileNumber.substring(0, 3)}-${mobileNumber.substring(3, 6)}-${mobileNumber.substring(6, mobileNumber.length)}`;
            let div = document.getElementsByClassName('igre-mobile-number-id-row');
            let hei = div[0].style.height.replace('px', '')
            div[0].style.height = parseInt(hei) + 8 + 'px';
            document.getElementsByClassName('igre-all-mobile-number')[0].appendChild(createMobileNumberNode(mobileNumber));
            e.target.value = "";
            hideerrorLabel();
        }
    }
    else {
        hideerrorLabel();
    }
    e.stopPropagation();
    e.preventDefault();
}

export const validateMobileNumber = (mobileNumber) => {
    if (mobileNumber) {
        return (mobileNumber.length >= 10 && mobileNumber.length <= 12);
    }
}

export const createMobileNumberNode = (mobileNumber) => {
    const spanNode = document.createElement("span");
    spanNode.className = "igre-mobile-number-ids";
    const aNode = document.createElement("a");
    aNode.className = "igre-cancel-mobile-number";
    aNode.addEventListener('click', function () {
        let res = this.parentNode;
        res.parentNode.removeChild(res);
        let div = document.getElementsByClassName('igre-mobile-number-id-row');
        let hei = div[0].style.height.replace('px', '')
        div[0].style.height = parseInt(hei) - 8 + 'px';
    })
    aNode.innerHTML = mobileNumber + '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="17.198" viewBox="0 0 17.199 17.198"><g id="Close" transform="translate(-410.288 -8330.738) rotate(45)"><path id="Path_513" data-name="Path 513" d="M6192.224,5603.756v-2.035c0-.342,0-.342-.353-.342-1.389,0-2.778,0-4.167,0a.773.773,0,1,1,.012-1.546q2.024,0,4.048,0a1.72,1.72,0,0,1,.213,0c.2.026.251-.071.251-.26-.006-1.4,0-2.793,0-4.189a1,1,0,0,1,.033-.328.779.779,0,0,1,.877-.522.751.751,0,0,1,.637.767c0,1.381,0,2.761,0,4.142,0,.389,0,.389.376.389,1.389,0,2.778,0,4.167,0a.75.75,0,0,1,.758.647.767.767,0,0,1-.559.872,1.315,1.315,0,0,1-.329.026q-2.024,0-4.048,0c-.365,0-.365,0-.365.377q0,2.058,0,4.118a.756.756,0,0,1-.815.809.748.748,0,0,1-.731-.773c0-.718,0-1.436,0-2.154Z" transform="translate(0)" fill="#fb0000"></path> </g></svg>';
    spanNode.appendChild(aNode);
    return spanNode;
}
export const refiReset = () => {
    let calculateRefiResultDetails = document.getElementById("igre-calculateRefiResultDetails");
    calculateRefiResultDetails.innerHTML = refiStaticResultDiv();
    let actionsShowHide = false;
    showHideAddlRefiActions(actionsShowHide);
}
export const saleReset = () => {
    let calculateSaleResultDetails = document.getElementById("igre-calculateSaleResultDetails");
    calculateSaleResultDetails.innerHTML = saleStaticResultDiv();
    let actionsShowHide = false;
    showHideAddlSaleActions(actionsShowHide);
}

export const reIssueYearHide = () => {
    let reIssueYearId = document.getElementById("igre-reissueyear");
    let reIssueYearCheckbox = document.getElementById("igre-reissueyear-checkbox");
    let reIssueYearLabel = document.getElementById("igre-reissueyear-label");
    let reIssueRefiYearId = document.getElementById("igre-refi-reissueyear");
    let reIssueYearRefiCheckbox = document.getElementById("igre-refi-reissueyear-checkbox");
    let reIssueYearRefiLabel = document.getElementById("igre-refi-reissueyear-label");
    reIssueYearId.style.display = 'none';
    reIssueYearLabel.innerHTML = "";
    reIssueYearCheckbox.checked = false;
    reIssueRefiYearId.style.display = 'none';
    reIssueYearRefiLabel.innerHTML = "";
    reIssueYearRefiCheckbox.checked = false;
}
